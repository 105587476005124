var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-box"},[_c('el-form',{ref:"form",staticClass:"form-box",attrs:{"model":_vm.form,"label-width":"140px"}},[_c('el-form-item',{attrs:{"label":"套餐类别：","prop":"type","rules":[
        { required: true, message: '请选择套餐类别', trigger: ['blur'] } ]}},[_c('el-select',{staticStyle:{"width":"350px"},attrs:{"disabled":_vm.isView},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},_vm._l((_vm.typeList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.target,"value":item.target}})}),1)],1),_c('el-form-item',{attrs:{"label":"主标题：","prop":"mealName","rules":[
        { required: true, message: '请输入主标题', trigger: ['blur'] } ]}},[_c('el-input',{staticStyle:{"width":"350px"},attrs:{"placeholder":"请输入","disabled":_vm.isView},model:{value:(_vm.form.mealName),callback:function ($$v) {_vm.$set(_vm.form, "mealName", $$v)},expression:"form.mealName"}})],1),_c('el-form-item',{attrs:{"label":"小标题：","prop":"subtitle","rules":[
        { required: true, message: '请输入小标题', trigger: ['blur'] } ]}},[_c('el-input',{staticStyle:{"width":"350px"},attrs:{"placeholder":"请输入","disabled":_vm.isView},model:{value:(_vm.form.subtitle),callback:function ($$v) {_vm.$set(_vm.form, "subtitle", $$v)},expression:"form.subtitle"}})],1),_c('el-form-item',{attrs:{"label":"封面图：","prop":"mealUrl","rules":[
        { required: true, message: '请上传封面图', trigger: ['blur'] } ]}},[_c('el-upload',{staticClass:"upload-demo1",attrs:{"disabled":_vm.isView,"action":_vm.projectName + '/common/backstage/file/fileupload',"headers":_vm.headers,"show-file-list":false,"on-success":_vm.avatarSuccess1}},[(_vm.form.mealUrl)?_c('el-image',{staticClass:"adv-img1",attrs:{"src":_vm.projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(_vm.form.mealUrl),"preview-src-list":[_vm.projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(_vm.form.mealUrl)]}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})],1)],1),_c('el-form-item',{attrs:{"label":"详情图：","prop":"mealDetailUrl","rules":[
        { required: true, message: '请上传详情图', trigger: ['blur'] } ]}},[_c('el-upload',{staticClass:"upload-demo2",attrs:{"disabled":_vm.isView,"action":_vm.projectName + '/common/backstage/file/fileupload',"headers":_vm.headers,"show-file-list":false,"on-success":_vm.avatarSuccess2}},[(_vm.form.mealDetailUrl)?_c('el-image',{staticClass:"adv-img2",attrs:{"src":_vm.projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(_vm.form.mealDetailUrl),"preview-src-list":[_vm.projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(_vm.form.mealDetailUrl)]}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})],1)],1),_c('el-form-item',{staticStyle:{"width":"350px"},attrs:{"label":"价格：","prop":"price","rules":[
      { required: true, message: '请输入价格', trigger: ['blur']},
      {required: true,pattern: /^[0-9]+[\.]?[0-9]{0,2}$/,message: '请输入数字或保留两位小数'} ]}},[_c('el-input',{staticStyle:{"width":"300px"},attrs:{"disabled":_vm.isView,"placeholder":"请输入"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}},[_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("元")])])],1)],1),(_vm.isView != true)?_c('div',{staticClass:"btn-box"},[_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取 消")]),(!_vm.mealId)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.makeSure}},[_vm._v("确 定")]):_vm._e(),(_vm.mealId)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.editSure}},[_vm._v("确 定")]):_vm._e()],1):_vm._e(),(_vm.isView == true)?_c('div',{staticClass:"btn-box"},[_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("返 回")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }