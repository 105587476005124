<template>
  <div class="add-box">
    <el-form :model="form" ref="form" label-width="140px" class="form-box">
      <el-form-item
        label="套餐类别："
        prop="type"
        :rules="[
          { required: true, message: '请选择套餐类别', trigger: ['blur'] },
        ]"
      >
        <el-select v-model="form.type" style="width: 350px" :disabled="isView">
          <el-option
            v-for="(item, index) in typeList"
            :key="index"
            :label="item.target"
            :value="item.target"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="主标题："
        prop="mealName"
        :rules="[
          { required: true, message: '请输入主标题', trigger: ['blur'] },
        ]"
      >
        <el-input
          v-model="form.mealName"
          style="width: 350px"
          placeholder="请输入"
          :disabled="isView"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="小标题："
        prop="subtitle"
        :rules="[
          { required: true, message: '请输入小标题', trigger: ['blur'] },
        ]"
      >
        <el-input
          v-model="form.subtitle"
          style="width: 350px"
          placeholder="请输入"
          :disabled="isView"
        ></el-input>
      </el-form-item>
      <el-form-item label="封面图：" prop="mealUrl" :rules="[
          { required: true, message: '请上传封面图', trigger: ['blur'] },
        ]">
        <el-upload
          :disabled="isView"
          class="upload-demo1"
          :action="projectName + '/common/backstage/file/fileupload'"
          :headers="headers"
          :show-file-list="false"
          :on-success="avatarSuccess1"
        >
        <el-image 
        v-if="form.mealUrl"
        :src="projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(form.mealUrl)"
            class="adv-img1"
    :preview-src-list="[projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(form.mealUrl)]">
  </el-image>
          <!-- <img
            v-if="form.mealUrl"
            :src="projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(form.mealUrl)"
            class="adv-img1"
          /> -->
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="详情图：" prop="mealDetailUrl" :rules="[
          { required: true, message: '请上传详情图', trigger: ['blur'] },
        ]">
        <el-upload
          :disabled="isView"
          class="upload-demo2"
          :action="projectName + '/common/backstage/file/fileupload'"
          :headers="headers"
          :show-file-list="false"
          :on-success="avatarSuccess2"
        >
        <el-image 
        v-if="form.mealDetailUrl"
        :src="projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(form.mealDetailUrl)"
            class="adv-img2"
    :preview-src-list="[projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(form.mealDetailUrl)]">
  </el-image>
          <!-- <img
            v-if="form.mealDetailUrl"
            :src="projectName + '/common/backstage/file/fileDownLoad?filePath=' + encodeURIComponent(form.mealDetailUrl)"
            class="adv-img2"
          /> -->
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="价格："  style="width: 350px" prop="price" 
      :rules="[
        { required: true, message: '请输入价格', trigger: ['blur']},
        {required: true,pattern: /^[0-9]+[\.]?[0-9]{0,2}$/,message: '请输入数字或保留两位小数'},
      ]">
        <el-input
          :disabled="isView"
          v-model="form.price"
          style="width: 300px"
          placeholder="请输入"
        >
        <span slot="suffix">元</span>
        </el-input>
      </el-form-item>
    </el-form>
    <div class="btn-box" v-if="isView != true">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="makeSure" v-if="!mealId">确 定</el-button>
      <el-button type="primary" @click="editSure" v-if="mealId">确 定</el-button>
    </div>
    <div class="btn-box" v-if="isView == true">
      <el-button @click="cancel">返 回</el-button>
    </div>
  </div>
</template>

<script>
import {packageAdd,packageEdit,particulars,categorylist} from "../../api/index";
import {projectName} from "../../util/config"
export default {
  data() {
    return {
      form: {
        type: "",
        mealName:"",
        subtitle:"",
        mealUrl:"",
        mealDetailUrl:"",
        price:"",
      },
      projectName:projectName,
      headers: {sessionId: sessionStorage.getItem("access_token"),},
      typeList: [],
      isView:false,
      mealId:"",
      current: 0,
    };
  },
  mounted() {
    this.getTypeData();
    if(this.$route.query.mealId) {
      this.mealId = this.$route.query.mealId;
      this.isView = this.$route.query.isView;
      this.current = this.$route.query.current;
      this.getDetails()
    }
  },
  methods: {
    getTypeData() {
      let data = {
        current:1,
        size:1000,
        type:1
      }
      categorylist(data).then(res=>{
      if(res.code==1) {
          this.typeList = res.data.records || [];
        }
      })
    },
    avatarSuccess1(res, file) {
      this.form.mealUrl = res.data.nginxFileReadPath
    },
    avatarSuccess2(res, file) {
      this.form.mealDetailUrl = res.data.nginxFileReadPath
    },
    cancel(){
      this.$router.push({name:"packageManagement",query:{current:this.current}})

    },
    makeSure() {
      let data = {
        ...this.form
      }
      this.$refs.form.validate((valid)=>{
        if(valid) {
          packageAdd(data).then(res=>{
            if(res.code==1) {
              this.$message.success("新增成功！");
              // this.$router.go(-1)
              this.$router.push({name:"packageManagement",query:{current:this.current}})
            }
          })
        }
      })
      
    },
    editSure() {
      let data = {
        ...this.form,
        mealId:this.mealId
      }
      this.$refs.form.validate((valid)=>{
        if(valid) {
          packageEdit(data).then(res=>{
            if(res.code==1) {
              this.$message.success("编辑成功！");
              // this.$router.go(-1)
              this.$router.push({name:"packageManagement",query:{current:this.current}})
            }
          })
        }
      })
    },
    getDetails() {
      particulars({mealId:this.mealId}).then(res=>{
        if(res.code==1) {
          this.form = res.data || {}
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.add-box {
  width: 100%;
  padding-top: 30px;
  .form-box {
    width: 900px;
    margin: 0 auto;
  }
}
.upload-demo1 /deep/.el-upload {
  width: 200px;
  height: 120px;
  border: 1px dashed #e5e5e5;
  .el-icon-plus {
    margin-top: 40px;
    font-size: 40px;
    color: #e5e5e5;
  }
}
.upload-demo2 /deep/.el-upload {
  width: 150px;
  height: 250px;
  border: 1px dashed #e5e5e5;
  .el-icon-plus {
    margin-top: 100px;
    font-size: 40px;
    color: #e5e5e5;
  }
}
.adv-img1 {
  width: 200px;
  height: 120px;
}
.adv-img2 {
  width: 150px;
  height: 250px;
}
.btn-box {
  text-align: center;
  margin-top: 50px;
}
</style>
